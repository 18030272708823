<template>
  <div class="main-container">
    <div class="main-header">
      <div class="logo-box">
        <img src="/images/logo.png" alt="">
      </div>
      <div class="name-box">韦氏儿童智力量表第四版中文版计分系统</div>
      <topMenu :num="3"></topMenu>
    </div>
    <div class="main-body">
      <div class="content-box">
        <div class="tabs-box">
          <div class="tabs-item active">个人资料修改</div>
          <div class="tabs-item" @click="goModifyPassword">修改密码</div>
          <div class="tabs-item" @click="goHistoryReport">历史报表查询</div>
          <div class="tabs-item" style="margin-left: 20px;width: 220px"><a style="color: #FFFFFF" href="http://wisc.king-may.com.cn/login.jhtml" target="_blank">2024年9月2日前报告查询</a></div>
        </div>
        <div class="form-box">
          <a-form-model ref="form" :model="formModel"  :rules="formRules" autoComplete="off" :label-col="labelCol" :wrapper-col="wrapperCol" labelAlign="left">
            <a-row>
              <a-col :span="11">
                <a-form-model-item label="主试姓名">
                  <span class="name">{{ formModel.name }}</span>
                </a-form-model-item>
              </a-col>
              <a-col :span="11">
                <a-form-model-item label="身份证号码">
                  <span class="name">{{formModel.id_card_no}}</span>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="11">
                <a-form-model-item label="证书编号">
                  <span class="name">{{formModel.certificate_no}}</span>
                </a-form-model-item>
              </a-col>
              <a-col :span="11">
                <a-form-model-item label="记分登录名">
                  <a-input allowClear placeholder="记分登录名" class="cu-input1" v-model="formModel.nick_name"/>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="11">
                <a-form-model-item label="E-mail地址">
                  <a-input allowClear placeholder="E-mail地址" class="cu-input1" v-model="formModel.email"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="11">
                <a-form-model-item label="联系电话">
                  <a-input allowClear placeholder="联系电话" class="cu-input1" v-model="formModel.mobile"/>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="11">
                <a-form-model-item label="单位名称">
                  <a-input allowClear placeholder="单位名称" class="cu-input1" v-model="formModel.company_name"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="11">
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="11">
                <a-form-model-item label="所在科室">
                  <a-input allowClear placeholder="所在科室" class="cu-input1" v-model="formModel.department"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="11">

              </a-col>
            </a-row>
            <a-row style="border-top: 1px solid #E9ECF2;padding-top: 40px;margin-top: 40px">
              <a-col :span="24" style="display: flex;justify-content: center;align-items: center;cursor: pointer">
                <span class="btn" @click="changeBaseInfo">保存</span>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
      </div>
    </div>
    <footer-bottom></footer-bottom>
  </div>
</template>

<script>
import * as Api from './api';
import {GetInfo} from '../../commonApi';
import {message} from "ant-design-vue";
export default {
  data() {
    return {
      labelCol: {span: 6, style: {color:'#7E8589',fontWeight: '500'}},
      wrapperCol: {span: 14},
      formModel: {
        email: '',
        company_name: '',
        department: '',
        nick_name: '',
        mobile: '',
      },
      formRules: {},
    };
  },
  mounted() {
    let abas_user = JSON.parse(window.localStorage.getItem('abas_user'));
    this.formModel = abas_user;
  },
  methods: {
    async changeBaseInfo() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          const res = await Api.ChangeBaseInfo(this.formModel);
          if (res && res.code == 0) {
            message.success({ content: res.message});
            this.getInfo();
          }
        }
      });
    },
    async getInfo() {
      const res = await GetInfo({});
      if (res && res.code == 0) {
        window.localStorage.setItem('abas_user', JSON.stringify(res.chiefExaminer))
      }
    },
    goModifyPassword() {
      this.$router.push({path: '/maintain-password-modify'});
    },
    goHistoryReport() {
      this.$router.push({path: '/maintain-history-report'});
    }
  }
};
</script>
<style>

</style>
<style scoped>
.main-body {
  background-image: url("/images/maintain-bg.png");
  align-items: flex-start;
  justify-content: flex-start;
}
.content-box {
  //margin: 50px;
  width: 100%;
}

.form-box {
  padding: 40px;
  background: #FFFFFF;
  box-shadow: 0px 8px 25px 0px rgba(242, 243, 245, 0.28);
  border-radius: 0px 12px 12px 12px;
}
.btn {
  background: #832B75;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  padding: 10px 40px;
}
.name {
  color: #656565;
  font-size: 14px;
  font-weight: 400;
  color: #4A4E51;
}
</style>
