import request from '@/utils/request';


// 修改信息
export function ChangeBaseInfo(data) {
  return request({
    url: '/wisc/youth/baseInfo/changeBaseInfo',
    method: 'POST',
    data
  });
}